<template>
  <div class="blog-area blog-detail">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="blog-detail-info">
            <h2 class="title">Relationship with us</h2>
            <p class="desc">
              Welcome to “GAME CHANGER”. These terms and conditions (hereinafter referred
              to as"Agreement") govern the collection, use, and protection of personal
              information by "GAME CHANGER” (hereinafter referred to as "we", "us", "our"
              or “Company”). By accessing our services and mobile app, you agree to comply
              with and be bound by this agreement.
            </p>

            <div class="list-area">
              <h2 class="title">Acknowledgement and Acceptance</h2>
              <p class="desc">
                BY USING THE SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ
                THESE TERMS AND CONDITIONS THEREFORE, YOU AGREE TO BE BOUND BY THEM.
              </p>
            </div>

            <div class="list-area">
              <h2 class="title">Personal Information Collection</h2>
              <p class="desc">
                We collect the following types of personal information from users,
                customers, or clients: name, gender (at birth), birthdate, handedness, and
                vision status (normal or corrected-to-normal). We obtain this information
                through voluntary user input during the registration process. The service
                provided is intended only for access and use by individuals at least
                eighteen (18) years old. By accessing or using Service, you warrant and
                represent that you are at least eighteen (18) years of age and with the
                full authority, right, and capacity to enter into this agreement and abide
                by all of the terms and conditions of Terms. If you are not at least
                eighteen (18) years old, you are prohibited from both the access and usage
                of the service.
              </p>
            </div>

            <div class="list-area">
              <h2 class="title">Use of Personal Information</h2>
              <p class="desc">
                The personal information we collect is used solely to determine the
                eligibility of individuals for the participation in research studies. It
                serves as a filter to match participants with suitable research
                opportunities. We do not share personal information with any third
                parties, including service providers or advertisers. We implement security
                measures to protect personal information from unauthorised access, use, or
                disclosure. These measures include the use of services that encrypt data
                en route.
              </p>
            </div>

            <p class="desc">
              DISCLAIMER: WE CONFORM AND COMPLY WITH THE SET REGULATIONS, POLICY, AND
              GUIDANCE OF THE IRB, OR INSTITUTE REVIEW BOARD, AND IT IS THEIR JOB TO
              CONFIRM THAT THE RESEARCH IS ETHICAL AND HUMANE.
            </p>

            <div class="list-area">
              <h2 class="title">User Rights</h2>
              <p class="desc">
                Users have the right to access, correct, or delete their personal
                information. Users can delete their account at any time, which will result
                in the deletion of their associated data.
              </p>
            </div>

            <div class="list-area">
              <h2 class="title">Disclaimer Of Warranty</h2>
              <p class="desc">
                THESE SERVICES ARE PROVIDED BY THE COMPANY ON AN “AS IS” AND “AS
                AVAILABLE” BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY
                KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE
                INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE
                THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS
                OBTAINED FROM US IS AT YOUR SOLE RISK. NEITHER COMPANY NOR ANY PERSON
                ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH
                RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR
                AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER
                COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT
                THE SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
                SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT
                DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES IT
                AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE
                SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL
                OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. COMPANY HEREBY DISCLAIMS ALL
                WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR
                OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY,
                NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE. THE FOREGOING DOES
                NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER
                APPLICABLE LAW.
              </p>
            </div>

            <div class="list-area">
              <h2 class="title">PAYMENT</h2>
              <p class="desc">
                The prices for our services are indicated on the application. You will
                have to pay in the currency quoted on the application, namely in United
                States Dollars (USD). You can use our service by filling out the required
                details and then following the payment procedures. You agree that you will
                pay all fees or charges to your account in accordance with the fees,
                charges, and billing terms in effect at the time the fee or charge is due
                and payable. You authorise “GAME CHANGER” to immediately charge your
                credit card or other payment account provided for all fees and charges due
                and payable and agree that no additional notice or consent is required.
                Since payments become due after the completion of a service you understand
                and agree that no amount already paid to our company is refundable. YOU
                ARE RESPONSIBLE FOR THE TIMELY PAYMENT OF ALL FEES AND FOR PROVIDING OUR
                COMPANY WITH A VALID PAYMENT METHOD.
              </p>
              <p class="desc">
                - PLEASE NOTE THAT OUR PAYMENT FOR OUR SERVICE SHALL BE MADE THROUGH A
                THIRD-PARTY PAYMENT PROCESSOR. BY PROVIDING PAYMENT DETAILS TO THE
                THIRD-PARTY PROCESSOR, YOU AGREE TO BE BOUND BY THEIR TERMS AND
                CONDITIONS. WE DO NOT STORE OR HAVE ACCESS TO YOUR PAYMENT INFORMATION. WE
                SHALL NOT BE LIABLE FOR ANY LOSS OR DAMAGE ARISING FROM THE USE OF THE
                THIRD-PARTY PAYMENT PROCESSOR. YOU ACKNOWLEDGE THAT THE THIRD-PARTY
                PAYMENT PROCESSOR HAS ITS OWN SECURITY MEASURES IN PLACE TO PROTECT YOUR
                PAYMENT INFORMATION, AND YOU AGREE TO COMPLY WITH ANY ADDITIONAL SECURITY
                MEASURES REQUIRED BY THE PAYMENT PROCESSOR.
              </p>
            </div>

            <div class="list-area">
              <h2 class="title">Limitation Of Liability</h2>
              <p class="desc">
                EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS,
                EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL,
                INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER, IT ARISES (INCLUDING
                ATTORNEYS’ FEES AND ALL RELATED COSTS AND EXPENSES OF LITIGATION AND
                ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION
                OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF CONTRACT,
                NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION
                WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL
                INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION
                BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR
                REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE
                POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS
                LIABILITY FOUND ON THE PART OF THE COMPANY, IT WILL BE LIMITED TO THE
                AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES
                WILL THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW
                THE EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL
                DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
              </p>
            </div>

            <div class="list-area">
              <h2 class="title">Changes To Service</h2>
              <p class="desc">
                We reserve the right to withdraw or amend our Service, and any service or
                material we provide via Service, in our sole discretion without notice. We
                will not be liable if for any reason all or any part of the Service is
                unavailable at any time or for any period. From time to time, we may
                restrict access to some parts of the Service, or the entire Service, to
                users, including registered users.
              </p>
            </div>

            <div class="list-area">
              <h2 class="title">Amendments To Terms</h2>
              <p class="desc">
                We may amend the Terms at any time by posting the amended terms on this
                application. It is your responsibility to review these Terms periodically.
                Your continued use of the Platform following the posting of revised Terms
                means that you accept and agree to the changes. You are expected to check
                this page frequently so you are aware of any changes, as they are binding
                on you. By continuing to access or use our Service after any revisions
                become effective, you agree to be bound by the revised terms. If you do
                not agree to the new terms, you are no longer authorised to use the
                Service.
              </p>
            </div>

            <div class="list-area">
              <h2 class="title">Cookies and Tracking Technologies</h2>
              <p class="desc">
                We may use cookies or other tracking technologies on our application.
              </p>
            </div>

            <div class="list-area">
              <h2 class="title">Links To Other Websites</h2>
              <p class="desc">
                Our Service may contain links to third-party websites or services that are
                not owned or controlled by “GAME CHANGER”. “GAME CHANGER” has no control
                over and assumes no responsibility for the content, privacy policies, or
                practices of any third-party websites or services. We do not warrant the
                offerings of any of these entities/individuals or their websites.
              </p>
            </div>

            <div class="list-area">
              <h2 class="title">Links To Other Websites</h2>
              <p class="desc">
                Our Service may contain links to third-party websites or services that are
                not owned or controlled by “GAME CHANGER”. “GAME CHANGER” has no control
                over and assumes no responsibility for the content, privacy policies, or
                practices of any third-party websites or services. We do not warrant the
                offerings of any of these entities/individuals or their websites.
              </p>

              <p class="desc">
                YOU ACKNOWLEDGE AND AGREE THAT “GAME CHANGER” SHALL NOT BE RESPONSIBLE OR
                LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED
                TO BE CAUSED BY OR IN CONNECTION WITH THE USE OF OR RELIANCE ON ANY SUCH
                CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY
                WEB SITES OR SERVICES. WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE
                AND PRIVACY POLICIES OF ANY THIRD-PARTY WEBSITES OR SERVICES THAT YOU
                VISIT.
              </p>
            </div>

            <div class="list-area">
              <h2 class="title">- Disclosure Regarding Google Display Advertising:</h2>
              <p class="desc">
                We may implement Google Analytics features that use Display Advertising
                information for Google Analytics Demographics and Interest Reporting. You
                may opt out of Google Analytics for Display Advertising, to prevent your
                data from being used by Google Analytics, by going to the Google Analytics
                opt-out page. We, along with third-party vendors (including Google), use
                first-party cookies (such as the Google Analytics cookies) and third-party
                cookies (such as DoubleClick cookies) to report how your ad impressions,
                other uses of ad services, and interactions with these ad impressions and
                ad services are related to visits to our Website. You can read more about
                the cookies used by Google Analytics advertising features, by going to the
                Google Analytics Privacy Policy page
              </p>
            </div>

            <div class="list-area">
              <h2 class="title">Termination</h2>
              <p class="desc">
                We may terminate or suspend your account and bar access to Service
                immediately, without prior notice or liability, under our sole discretion,
                for any reason whatsoever and without limitation, including but not
                limited to a breach of Terms. If you wish to terminate your account, you
                may simply discontinue using the Service. All provisions of Terms which by
                their nature should survive termination shall survive termination,
                including, without limitation, ownership provisions, warranty disclaimers,
                indemnity and limitations of liability. Violation of any of the terms and
                conditions of this agreement may result in immediate termination of your
                account, without notice, and may also result in legal action being taken
                against you. In addition, we reserve the right to take any other measures
                we deem necessary to protect our rights and interests. This clause makes
                it clear that if a user violates the terms and conditions, there will be
                serious consequences, including termination of their account and potential
                legal action. It also emphasises that the company reserves the right to
                take any other actions it deems necessary to protect its interests.
              </p>
            </div>

            <div class="list-area">
              <h2 class="title">Governing Law & Jurisdiction</h2>
              <p class="desc">
                These Terms and Conditions shall be governed and construed in accordance
                with the laws of THE STATE OF NEW YORK. The aforementioned governing law
                applies to these terms and conditions regardless of its conflict of law
                provisions. Whenever you have questions or concerns regarding our
                services, please do not hesitate to contact us through our customer
                service support email mentioned below. If a dispute arises that can’t be
                settled amicably, it shall be settled by arbitration in accordance with
                the Rules of the New York International Arbitration Center ( NYIAC ).
              </p>
            </div>

            <div class="list-area">
              <h2 class="title">Waiver and Severability</h2>
              <p class="desc">
                No waiver by the Company of any term or condition set forth in Terms shall
                be deemed a further or continuing waiver of such term or condition or a
                waiver of any other term or condition, and any failure of the Company to
                assert a right or provision under Terms shall not constitute a waiver of
                such right or provision. If any provision of Terms is held by a competent
                jurisdiction to be invalid, illegal or unenforceable for any reason, such
                provision shall be eliminated or limited to the minimum extent such that
                the remaining provisions of Terms will continue in full force and effect.
              </p>
            </div>

            <div class="list-area">
              <h2 class="title">Amendments to Terms</h2>
              <p class="desc">
                Please note that these Terms and Conditions may be subject to change or
                update. We encourage you to review this Agreement periodically to stay
                informed of any modifications. Additionally, We may freely assign these
                Terms or their amendments in connection with a merger, acquisition, or
                sale of assets, or by operation of law or for any reason otherwise.
              </p>
            </div>

            <div class="list-area">
              <h2 class="title">Google Play</h2>
              <p class="desc">
                By downloading the Platform from Google Play (or its successors) operated
                by Google, Inc. or one of its affiliates (“Google”), you specifically
                acknowledge and agree that: to the extent of any conflict between (a) the
                Google Play Terms of Services and the Google Play Business and Program
                Policies or such other terms which Google designates as default end-user
                licence terms for Google Play (all of which together are referred to as
                the “Google Play Terms”), and (b) the other terms and conditions in these
                Terms, the Google Play Terms shall apply with respect to your use of the
                Platform that you download from Google Play, and you hereby acknowledge
                that Google does not have any responsibility or liability related to
                compliance or non-compliance by “GAME CHANGER” or you (or any other user)
                under these Terms or the Google Play Terms.
              </p>
            </div>

            <div class="list-area">
              <h2 class="title">App Store</h2>
              <p class="desc">
                To the extent permitted by applicable law, the following supplemental
                terms shall apply when accessing the Platform through specific devices:
              </p>
            </div>

            <div class="list-area">
              <h2 class="title">- Notice regarding Apple.</h2>
              <p class="desc">
                By downloading the Platform from a device made by Apple, Inc. (“Apple”) or
                from Apple’s App Store, you specifically acknowledge and agree that: These
                Terms between “GAME CHANGER” and you; Apple is not a party to these Terms.
                The license granted to you hereunder is limited to a personal, limited,
                non-exclusive, non-transferable right to install the Platform on the Apple
                device(s) authorised by Apple that you own or control for personal,
                non-commercial use, subject to the Usage Rules set forth in Apple’s App
                Store Terms of Services. Apple is not responsible for the Platform or the
                content thereof and has no obligation whatsoever to furnish any
                maintenance or support services with respect to the Platform.In the event
                of any failure of the Platform to conform to any applicable warranty, you
                may notify Apple, and Apple will refund the purchase price for the
                Platform, if any, to you. To the maximum extent permitted by applicable
                law, Apple will have no other warranty obligation whatsoever with respect
                to the Platform. Apple is not responsible for addressing any claims by you
                or a third party relating to the Platform or your possession or use of the
                Platform, including without limitation (a) product liability claims; (b)
                any claim that the Platform fails to conform to any applicable legal or
                regulatory requirement; and (c) claims arising under consumer protection
                or similar legislation. In the event of any third party claim that the
                Platform or your possession and use of the Platform infringes such third
                party’s intellectual property rights, Apple is not responsible for the
                investigation, defence, settlement or discharge of such intellectual
                property infringement claim. You represent and warrant that (a) you are
                not located in a country that is subject to a U.S. Government embargo, or
                that has been designated by the U.S. Government as a “terrorist
                supporting” country; and (b) you are not listed on any U.S. Government
                list of prohibited or restricted parties. Apple and its subsidiaries are
                third party beneficiaries of these Terms and upon your acceptance of the
                terms and conditions of these Terms, Apple will have the right (and will
                be deemed to have accepted the right) to enforce these Terms against you
                as a third party beneficiary hereof.
              </p>
            </div>

            <div class="list-area">
              <h2 class="title">Contact us</h2>
              <p class="desc">
                In case you face any difficulties, problems while using our service
                through our app or have any inquiries regarding these terms and
                conditions, kindly contact us via our customer service support email
                address:
                <span style="color: #07135b"> support@gamechanger.solutions</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import blogs from "../data/blog.json";

export default {
  data() {
    return {};
  },
  computed: {},
};
</script>
