<template>
  <!-- Begin Main Header Area -->
  <header class="header">
    <div
      class="header-area header-position-absolute header-sticky header-style-5"
      :class="{ 'is-active': isSticky }"
    >
      <div class="container header-container">
        <div class="row align-items-center">
          <div class="col-xl-3 col-lg-2 col-6">
            <router-link class="header-logo" to="/">
              <img
                width="80"
                class="primary-img"
                src="images/logo/4-1.png"
                alt="GameChanger"
              />
              <img
                width="80"
                class="sticky-img"
                src="images/logo/4-1.png"
                alt="Header Logo"
              />
              <!-- <strong
                class="header-drop-holder"
                style="color: #ff2324; font-size: 18px; bold;"
              >
                GameChanger</strong
              > -->
            </router-link>
          </div>
          <div class="col-xl-6 col-lg-8 d-none d-lg-block">
            <div class="header-menu text-lg-center">
              <nav class="header-menu-nav onepage-nav">
                <ul>
                  <li class="header-drop-holder">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="active">
                    <a href="#feature">Features</a>
                  </li>
                  <li>
                    <a href="#testimonial">Testimonial</a>
                  </li>
                  <li class="header-drop-holder">
                    <router-link to="/faq">FAQ</router-link>
                  </li>
                  <li>
                    <router-link to="/contact">Contact</router-link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div class="col-xl-3 col-lg-2 col-6">
            <ul class="header-right">
              <li class="d-none d-md-block">
                <a
                  class="btn btn-custom-size btn-md extbot-primary-5 blackcurrant-hover"
                  href="https://prof.gamechanger.solutions"
                  target="_blank"
                  rel="noreferrer"
                  >Sign In</a
                >
              </li>
              <li class="d-flex d-lg-none">
                <button
                  class="btn p-0"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasWithBothOptions"
                  aria-controls="offcanvasWithBothOptions"
                >
                  <i class="icofont-navigation-menu"></i>
                </button>
              </li>
              <li class="setting-btn-wrap d-block d-md-none">
                <button
                  class="setting-btn bg-transparent"
                  v-on:click="isHidden = !isHidden"
                >
                  <i class="icofont-ui-settings"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="setting-body" v-if="isHidden">
          <ul class="header-right setting-item">
            <li>
              <a
                href="https://prof.gamechanger.solutions"
                target="_blank"
                rel="noreferrer"
                >Creat an Account
              </a>
            </li>
            <li>
              <a
                class="btn btn-custom-size btn-md sapphire-color extbot-primary-hover"
                href="https://prof.gamechanger.solutions"
                target="_blank"
                rel="noreferrer"
                >Sign In</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="offcanvas offcanvas-start"
      data-bs-scroll="true"
      tabindex="-1"
      id="offcanvasWithBothOptions"
    >
      <div class="offcanvas-header">
        <router-link to="/home-5" class="header-logo">
          <img src="/images/logo/1-1.png" alt="Header Logo" />
        </router-link>
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body">
        <div class="header-offcanvas-menu">
          <nav class="header-offcanvas-nav onepage-offcanvas-nav">
            <ul>
              <li>
                <div class="dropdown d-grid">
                  <button
                    type="button"
                    class="btn dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Home
                    <i class="icofont-rounded-down"></i>
                  </button>
                </div>
              </li>
              <li class="active">
                <a href="#feature">Features</a>
              </li>
              <li>
                <a href="#testimonial">Testimonial</a>
              </li>
              <li>
                <div class="dropdown d-grid">
                  <button
                    type="button"
                    class="btn dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    FAQ
                    <i class="icofont-rounded-down"></i>
                  </button>
                </div>
              </li>
              <li>
                <router-link to="/contact">Contact</router-link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </header>
  <!-- Main Header Area End Here -->
</template>
<script>
export default {
  data() {
    return {
      isSticky: false,
      isHidden: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", () => {
      const scrollPos = window.scrollY;
      if (scrollPos >= 200) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    });
  },
};
</script>

<style lang="scss">
.header {
  &.fixed-top {
    z-index: 99;
  }
}
.header-toggler {
  background: transparent;
  border: 0px;
  color: #fff;
  font-size: 26px;
  &:hover {
    color: #6e5a49;
  }
}
</style>
