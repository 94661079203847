<template>
  <div class="wrapper">
    <router-view></router-view>
    <HeaderNavFive></HeaderNavFive>
    <div class="main-content">
      <Breadcrumb
        breadcrumbTitle="Term & Condition"
        breadcrumbName="Term & Condition"
      ></Breadcrumb>
      <SingleBlog></SingleBlog>
    </div>
    <div class="newsletter-area style-4 newsletter-space-y-axis">
      <NewsletterFour newslatterFour="newsletter-area style-4"></NewsletterFour>
    </div>
    <FooterSectionFive></FooterSectionFive>
  </div>
</template>

<script>
import HeaderNavFive from "@/components/HeaderNavFive.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import SingleBlog from "@/components/SingleBlog.vue";
import NewsletterFour from "@/components/NewsletterFour.vue";
import FooterSectionFive from "@/components/FooterSectionFive.vue";
import { Disqus } from "vue-disqus";
export default {
  components: {
    HeaderNavFive,
    Breadcrumb,
    SingleBlog,
    NewsletterFour,
    FooterSectionFive,
    Disqus,
  },
};
</script>
